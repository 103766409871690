import React, {useCallback, useEffect, useMemo, useState} from "react";
import cookies from "browser-cookies";
import Logger from "../logger";
import JwtDecode from "jwt-decode";
import * as Sentry from "@sentry/nextjs";
import {AssertionOptions} from "../__generated__/AssertionOptions";
import {
    ASSERTION_OPTIONS,
    ASSERTION_VERIFICATION,
    AUTHENTICATE_WITH_IDP_CODE,
    AUTHENTICATE_WITH_IDP_ID_TOKEN,
    AUTHENTICATE_WITH_PW,
    CURRENT_USER,
    GET_CUSTOMER
} from "../queries";
import {AssertionCredentialJSON, PublicKeyCredentialRequestOptionsJSON} from "@simplewebauthn/typescript-types";
import {startAssertion} from "@simplewebauthn/browser";
import {FetchResult, useApolloClient, useMutation, useReactiveVar} from "@apollo/client";
import {AssertionVerification, AssertionVerificationVariables} from "../__generated__/AssertionVerification";
import {AuthenticateWithIdpCode, AuthenticateWithIdpCodeVariables} from "../__generated__/AuthenticateWithIdpCode";
import {
    ApplicationEvents,
    ApplicationName,
    AuthenticationFailureReason,
    CustomerAccountType,
    QueryKeys,
    StorageKeys,
    Topics
} from "../constants";
import {applicationUser} from "../cache";
import {IApplicationUser, ICustomerAccount, IFavouriteList, IRequiredDocument} from "../types";
import {CurrentUser} from "../__generated__/CurrentUser";
import {useSocket} from "use-socketio";
import {Socket} from "socket.io-client";
import {CustomerEventType, ICustomersEvent, IUserEvent, UserEventType} from "../events";
import {GetCustomer, GetCustomerVariables} from "../__generated__/GetCustomer";
import Errors from "../errors";
import {Authenticate, AuthenticateVariables} from "../__generated__/Authenticate";
import {IdentityProvider} from "../__generated__/globalTypes";
import {
    AuthenticateWithIdpIdToken,
    AuthenticateWithIdpIdTokenVariables
} from "../__generated__/AuthenticateWithIdpIdToken";
import {useRouter} from "next/router";
import dynamic from "next/dynamic";
import Routes from "../routes";

const SignInDialog = dynamic(() => import("../components/auth/signInDialog"));
const ValidateMobileDialog = dynamic(() => import("../components/dialogs/validate-mobile-dialog"));

const AUTH_COOKIE_NAME = "_ramtoken";
export const AUTH_STORAGE_KEY = "reis-token";

export interface IdpOptions {
    redirectUri?: string;
    provider?: IdentityProvider;
}

interface IJwtToken {
    exp?: number;
    sub?: string;
    name?: string;
    given_name?: string;
    family_name?: string;
    email?: string;
    realm_access?: {
        roles?: string[];
    }
}

export interface ITokenResponse {
    access_token: string;
    expires_in: number;
    refresh_token: number;
}

export interface IdentityProviderProfile {
    firstName?: string;
    lastName?: string;
    picture?: string;
    email: string;
}

export interface IAuthenticationResponse {
    success: boolean;
    token?: string;
    failureReason?: AuthenticationFailureReason;
    user?: IApplicationUser;
    /**
     * In cases where an IDP is used to authenticate, a
     * user might authenticate successfully but not have
     * created a user account with us. In this case,
     * failureReason will be set to
     * AuthenticationFailureReason.NoUserMatchingIdpEmailAddress and
     * we will return the user information we were given by the IDP.
     * This can be used to populate the user account creation fields.
     */
    identityProfile?: IdentityProviderProfile;
}

export interface IAuthContext {
    user: IApplicationUser|null;
    checkingIdentity: boolean;
    isUserLinkedToProAccount: boolean;
    linkedProAccounts: ICustomerAccount[];
    activeCustomerAccount: ICustomerAccount|null;
    setActiveCustomerAccount: (id: string) => void;
    loadingCustomerAccount: boolean;
    supportsCredentialManagement: boolean;
    showSignInDialog: (show: boolean) => void;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<boolean>;
    signInWithAuthenticator: () => Promise<boolean>;
    signInWithIdpCode: (code: string, providerName: string, options?: IdpOptions) => Promise<IAuthenticationResponse>;
    signInWithIdpIdToken: (token: string) => Promise<IAuthenticationResponse>;
    setAccessToken: (token: string) => Promise<boolean>;
    getCurrentAccessToken: () => Promise<string|null>;
    signOut: () => void;
    allowAutoSignIn: (allow: boolean) => void;
}

export const AuthContext = React.createContext<IAuthContext>({
    user: null,
    checkingIdentity: false,
    isUserLinkedToProAccount: false,
    linkedProAccounts: [],
    activeCustomerAccount: null,
    setActiveCustomerAccount: () => null,
    loadingCustomerAccount: false,
    supportsCredentialManagement: false,
    showSignInDialog: () => null,
    signInWithEmailAndPassword: async () => false,
    signInWithAuthenticator: async () => false,
    signInWithIdpCode: async () => null,
    signInWithIdpIdToken: async () => null,
    setAccessToken: async () => false,
    getCurrentAccessToken: async () => null,
    signOut: () => {},
    allowAutoSignIn: ()=> {}
});

/**
 * Persists an oauth access token.
 *
 * @param token
 * @param writeCookie Whether to write the access token to a cookie in order to have it read by the legacy app.
 */
function persistAccessToken(token: string, writeCookie = true) {
    localStorage.setItem(AUTH_STORAGE_KEY, token);
    //
    // This is not being handled correctly in all circumstances, and we end
    // up writing the entire OIDC token to the cookie, which can cause an
    // HTTP request to exceed the max length (431).
    //
    // if(writeCookie) {
    //     cookies.set(AUTH_COOKIE_NAME, token);
    // }
}

/**
 * Removes any persisted access tokens.
 */
export function removeAccessToken() {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    cookies.erase(AUTH_COOKIE_NAME);
}

/**
 * Validates an access token by decoding it and checking it hasn't expired
 * etc.
 *
 * @param token
 */
export async function validateAccessToken(token: string): Promise<IJwtToken|null> {
    try {
        const result = JwtDecode<IJwtToken>(token);
        if(!result.sub || !result.exp) {
            return null;
        }

        //
        // Check if the token has expired. If so, delete the persisted
        // token and return null. Expiry time in the JWT is expressed
        // as seconds since the epoch, whereas JS Date.now() is ms since
        // the epoch, so we need to adjust.
        //
        if((Date.now()/1000) > result.exp) {
            removeAccessToken();
            return null;
        }
        return result;
    }
    catch(exc) {
        return null;
    }
}

const AuthContextProvider: React.FC = (props) => {
    const apollo = useApolloClient();
    const router = useRouter();
    const {socket}: {socket: Socket} = useSocket();
    const [initialising, setInitialising] = useState<boolean>(true);
    const [credentialManagement, setCredentialManagement] = useState<boolean>(false);
    const [autoSignIn, setAutoSignIn] = useState<boolean>(true);
    const [showSignIn, setShowSignIn] = useState<boolean>(false);
    const [loadSignInDialog, setLoadSignInDialog] = useState<boolean>(false);
    const [authenticate] = useMutation<Authenticate, AuthenticateVariables>(AUTHENTICATE_WITH_PW);
    const [authenticateWithIdpCode] = useMutation<AuthenticateWithIdpCode, AuthenticateWithIdpCodeVariables>(AUTHENTICATE_WITH_IDP_CODE);
    const [authenticateWithIdpIdToken] = useMutation<AuthenticateWithIdpIdToken, AuthenticateWithIdpIdTokenVariables>(AUTHENTICATE_WITH_IDP_ID_TOKEN);
    const user = useReactiveVar(applicationUser);
    const [customerAccount, setCustomerAccount] = useState<ICustomerAccount|null>(null);
    const [loadingCustomer, setLoadingCustomer] = useState<boolean>(false);
    const [verifyAssertion] = useMutation<AssertionVerification, AssertionVerificationVariables>(ASSERTION_VERIFICATION);
    const activeCustomerId = useMemo(() => {
        return customerAccount?.id;
    }, [customerAccount]);

    useEffect(() => {
        if(showSignIn) {
            setLoadSignInDialog(true);
        }
    }, [showSignIn]);

    const showValidateMobile = useMemo(() => !!user && !user.mobileNumberIsValidated, [user]);

    /**
     * Fetches details about the current user from the API.
     */
    const fetchUser = useCallback(async (): Promise<IApplicationUser> => {
        try {
            const result = await apollo.query<CurrentUser>({
                query: CURRENT_USER,
                fetchPolicy: "network-only"
            });

            const userData = result.data.me;

            return {
                id: userData.id,
                firstName: userData.firstName,
                lastName: userData.lastName,
                fullName: `${userData.firstName} ${userData.lastName}`,
                enabled: userData.enabled,
                emailAddress: userData.email,
                mobileNumber: userData.mobileNumber,
                idVerified: userData.idVerified,
                credentials: userData.credentials,
                customerAccounts: (userData.customerAccounts ?? []),
                requiredDocuments: (userData.profile?.requiredDocuments ?? []) as IRequiredDocument[],
                favouriteLists: (userData.profile?.favouriteLists ?? []) as IFavouriteList[],
                mustProvideId: userData.profile?.mustProvideId ?? false,
                mobileNumberIsValidated: userData.profile?.mobileValidation?.isValidated ?? false,
                roles: []
            }
        }
        catch(exc) {
            Sentry.captureException(exc);
        }
    }, [apollo]);

    /**
     * Fetches a customer account and sets it as the active customer account.
     */
    const setActiveCustomerAccount = useCallback(async (id: string, setLoadingStatus=true) => {
        if(!id) {
            Logger.error("setActiveCustomerAccount called with a null ID", "authContext");
            return;
        }

        // Don't do this or else customer data won't be re-fetched on customer account
        // updated events.
        // if(activeCustomerId === id) {
        //     return;
        // }

        if(setLoadingStatus) {
            setLoadingCustomer(true);
        }

        const {data,error} = await apollo.query<GetCustomer,GetCustomerVariables>({
            query: GET_CUSTOMER,
            fetchPolicy: "network-only",
            variables: {
                id: id
            }
        });

        if(error) {
            Logger.error(error.message, "authContext");
            Sentry.captureException(
                new Error(error?.message),
                {
                    contexts: {
                        operation: {
                            description: "Attempting to set active customer account"
                        }
                    }
                }
            );
        }
        else {
            const customer = data.customer as ICustomerAccount;
            setCustomerAccount(customer);
            //
            // Record the last active customer account for the current device.
            //
            try {
                window.localStorage.setItem(StorageKeys.ActiveCustomer, customer.id);
            }
            catch(exc) {}
        }
        setLoadingCustomer(false);
    }, [apollo]);

    /**
     * Sets the application user. Queries the API for their customer accounts
     * and sets the active customer account to the first account in the collection
     * of accounts for which they are authorised.
     */
    const setActiveUser = useCallback(async (userToken: IJwtToken, accessTokenToPersist?: string, writeCookie = false) => {
        if(!userToken) {
            return;
        }

        if(accessTokenToPersist) {
            persistAccessToken(accessTokenToPersist, writeCookie);
        }

        //
        // Now query the API for the expanded user info we need. Set the application
        // user based on both the access token and the API query result.
        //
        let appUser = await fetchUser();

        appUser = {
            ...appUser,
            roles: userToken.realm_access?.roles ?? []
        };
        applicationUser(appUser);

        //
        // Check if we have a local record of the customer account last used by this
        // user. If we do and they are still authorised for that account, set
        // that customer account as the active one. Otherwise, use the first one in
        // the collection of authorised customer accounts.
        //
        let lastActiveCustomerId: string|null = null;
        try {
            lastActiveCustomerId = window.localStorage.getItem(StorageKeys.ActiveCustomer);
        }
        catch(exc) {}

        if(lastActiveCustomerId && appUser.customerAccounts?.some(cust => cust.id === lastActiveCustomerId)) {
            await setCustomerAccount(appUser.customerAccounts.find(cust => cust.id === lastActiveCustomerId));
        }
        else {
            await setActiveCustomerAccount(appUser.customerAccounts?.[0]?.id ?? null);
        }

        //
        // Add the user to the current Sentry scope in order to identify customers
        // having problems with the app.
        //
        Sentry.configureScope(scope => {
            scope.setUser({
                email: appUser.emailAddress,
                username: appUser.fullName,
                id: appUser.id
            });
        });

        setInitialising(false);
        return appUser;
    }, [fetchUser, setActiveCustomerAccount]);

    const isUserLinkedToProAccount = useMemo(() => {
        return (user?.customerAccounts ?? []).some(account => account.accountType === CustomerAccountType.Pro);
    }, [user]);

    const linkedProAccounts = useMemo(() => {
        return (user?.customerAccounts ?? []).filter(account => account.accountType === CustomerAccountType.Pro);
    }, [user]);

    useEffect(() => {
        //
        // Only do this auth stuff if we are not handling an OAuth callback.
        //
        if(/\/oauth-callback/.test(window.location.pathname)) {
            setInitialising(false);
            return;
        }

        const init = async () => {
            const supportsCredentialManagement = "credentials" in navigator;
            setCredentialManagement(supportsCredentialManagement);

            //
            // Check if we have a stored access token. If we do, check
            // its validity.
            //
            Logger.info("Looking for access token in local storage...", "AuthContext");
            let token = window.localStorage.getItem(AUTH_STORAGE_KEY);
            if(token) {
                Logger.info("Found access token in local storage. Checking validity...", "AuthContext");
                const tokenUser = await validateAccessToken(token);
                if(tokenUser) {
                    await setActiveUser(tokenUser);
                    return;
                }
                else {
                    Logger.info("Null user returned from token validation.", "AuthContext");
                }
            }

            //
            // If auto sign in is false, don't go past this point. We do this here partly to not
            // immediately sign in a user that has just signed out but has a legacy _ramtoken
            // present.
            //
            if(!autoSignIn) {
                setInitialising(false);
                return;
            }

            //
            // Look for a cookie set by the legacy app. A user might have authenticated
            // via that app.
            //
            Logger.info("No access token found in storage. Checking cookies...", "AuthContext");
            token = cookies.get(AUTH_COOKIE_NAME);
            if(token) {
                Logger.info("Found access token in cookie. Checking validity...", "AuthContext");
                const tokenUser = await validateAccessToken(token);
                if(tokenUser) {
                    await setActiveUser(tokenUser, token, false);
                    return;
                }
                else {
                    Logger.info("Null user returned from token validation.", "AuthContext");
                }
            }
            else {
                Logger.info("No access token found in cookies either.", "AuthContext");
            }

            //
            // If a user is trying to reset their password, we don't want to go any further as they
            // most likely have incorrect credentials saved and this just gets annoying if we try to
            // sign them in using credentials they already know to be bad.
            //
            if(/\/reset-password/.test(window.location.pathname)) {
                setInitialising(false);
                return;
            }

            //
            // At this stage we don't have a user. Let's check if we have any stored credentials
            // we can use to sign the user in (if the browser supports the credential management
            // API - specifically passwords).
            //
            const params = new URL(window.location.href).searchParams;
            if(!params.has(QueryKeys.NoCredentials) && supportsCredentialManagement && "PasswordCredential" in window) {
                Logger.info("This browser supports the credential management API (password). Checking for stored credentials...");
                const credentials = await navigator.credentials.get({
                    password: true,
                    mediation: "optional"
                });
                if(credentials) {
                    //
                    // We have credentials - check if they are of type password and that we
                    // have a 'name' (email address) to try to sign in with.
                    //
                    if(credentials.type === "password" && credentials.name) {
                        Logger.info("Found password credentials. Attempting to sign in...");
                        const pwCredentials: PasswordCredential = credentials;
                        let authenticationResult;
                        try {
                            authenticationResult = await authenticate({
                                variables: {
                                    email: pwCredentials.name,
                                    password: pwCredentials.password,
                                    context: ApplicationName
                                }
                            });
                        }
                        catch(exc) {
                            Logger.info("Password authentication failed.");
                            setInitialising(false);
                            return;
                        }

                        const {success, token} = authenticationResult.data?.authenticate;
                        if(success) {
                            Logger.info("Password authentication was successful. Checking access token...");

                            let tokenResponse: ITokenResponse;
                            try {
                                tokenResponse = JSON.parse(token);
                            }
                            catch(exc) {
                                Logger.error("Error when parsing token returned from authenticate call.");
                            }

                            const user = await validateAccessToken(tokenResponse.access_token);
                            if(user) {
                                Logger.info("Found user information in access token. Setting user.");
                                await setActiveUser(user, tokenResponse.access_token, true);
                                return;
                            }
                            else {
                                Logger.error("Could not read user information from the access token.");
                            }
                        }
                        else {
                            Logger.info("Password authentication failed.");
                        }
                    }
                }
                else {
                    Logger.info("No stored credentials found.");
                }
            }
            else {
                Logger.info("Browser does not support the credential management API (password) - cannot check for stored credentials.")
            }
            setInitialising(false);
        }

        init().catch(reason => {
           Logger.error(`Failed to check current identity - ${reason}`);
        });
    }, [authenticate, autoSignIn, setActiveUser]);

    /**
     * Because we show the sign in dialog, make sure it is hidden whenever
     * the route changes.
     */
    useEffect(() => {
        const handleRouteStart = () => {
            setShowSignIn(false);
        }

        router.events.on("routeChangeStart", handleRouteStart);
        return () => {
            router.events.off("routeChangeStart", handleRouteStart);
        }
    }, [router]);

    const signOut = useCallback(async () => {
        setAutoSignIn(false);
        removeAccessToken();
        applicationUser(null);
        setCustomerAccount(null);
        document.dispatchEvent(new Event(ApplicationEvents.SignOut));
    }, []);

    /**
     * Use effect hook to subscribe to user account update events.
     */
    useEffect(() => {
        if(!user || !socket) {
            return;
        }

        async function handleUserEvent(event: IUserEvent) {
            Logger.info(`Handling user topic event of type ${event.type}`);
            if(event.type === UserEventType.DetailsUpdated) {
                //
                // Fetch the latest user data and update our local state. We
                // don't get roles from the API so use roles set when we
                // initially read the access token.
                //
                const appUser = await fetchUser();
                applicationUser({
                    ...appUser,
                    roles: user.roles
                });
            }
        }

        const eventName = `${Topics.Users}-user-${user.id}`;
        socket.on(eventName, handleUserEvent);

        return () => {
            socket.off(eventName, handleUserEvent);
        }
    }, [user, socket, fetchUser]);

    //
    // Watch for the user account being disabled or being marked as must providing ID.
    //
    useEffect(() => {
        if(user) {
            if(!user.enabled) {
                signOut().then();
            }
            else if(!user.idVerified && user.mustProvideId && router.asPath !== Routes.Accounts.RequiredDocuments) {
                router.replace(Routes.Accounts.RequiredDocuments).then();
            }
        }
    }, [user, signOut, router]);

    /**
     * Listen for updates to the active customer account. Re-fetch
     * the customer data when this event happens so that the state
     * changes propagate through the app.
     */
    useEffect(() => {
        if(!socket || !activeCustomerId || !user) {
            return;
        }

        async function handleCustomerEvent(event: ICustomersEvent) {
            Logger.info(`Handling customer event of type: ${event.type}`, "AuthContext");
            if(![
                    CustomerEventType.DetailsUpdated,
                    CustomerEventType.CardAddedToAccount,
                    CustomerEventType.CardRemovedFromAccount,
                    CustomerEventType.AccountTypeChanged
                ].includes(event.type)) {
                return;
            }

            await setActiveCustomerAccount(activeCustomerId, false);

            //
            // If the active customer account type has changed, re-fetch the user
            // details so that the account type will be reflected in the customer
            // account selector.
            //
            if(event.type === CustomerEventType.AccountTypeChanged) {
                Logger.info("Active customer account type has changed", "AuthContext");
                const freshUser = await fetchUser();
                applicationUser({
                    ...freshUser,
                    roles: user.roles
                });
            }
        }

        const eventName = `${Topics.Customers}-customer-${activeCustomerId}`;
        socket.on(eventName, handleCustomerEvent);

        return () => {
            socket.off(eventName, handleCustomerEvent);
        }

    }, [socket, activeCustomerId, user, fetchUser, setActiveCustomerAccount, apollo]);

    /**
     * Email / password sign in. Saves the credentials if the login is successful.
     */
    const signInWithEmailPassword = useCallback(async (email: string, password: string): Promise<boolean> => {
        let authenticationResult;
        try {
            authenticationResult = await authenticate({
                variables: {
                    email: email,
                    password: password,
                    context: ApplicationName
                }
            });
        }
        catch(exc) {
            //
            // If the exception is anything other than an AUTHENTICATION_FAILURE response,
            // log the exception to Sentry.
            //
            Logger.info("Authentication exception.");
            if(exc.message !== Errors.AuthenticationFailure) {
                Sentry.captureException(exc);
            }
            return false;
        }

        const {success, token} = authenticationResult.data?.authenticate;
        if(!success) {
            return false;
        }

        //
        // Store the access token.
        //
        let tokenResponse: ITokenResponse;
        try {
            tokenResponse = JSON.parse(token);
        }
        catch(exc) {
            Sentry.captureException(exc);
            Logger.error("Error when parsing token returned from authenticate call.");
            return false;
        }

        //
        // Set the user from the token details.
        //
        const user = await validateAccessToken(tokenResponse.access_token);
        await setActiveUser(user, tokenResponse.access_token, true);

        //
        // Use the credential management API to store the password. We
        // will use it to automatically sign the user in on subsequent
        // visits.
        //
        const params = new URL(window.location.href).searchParams;
        if(!params.has(QueryKeys.NoCredentials) && "PasswordCredential" in window) {
            const credential = new PasswordCredential({
                id: email,
                name: email,
                password: password
            });
            await navigator.credentials.store(credential);
        }
        return true;
    }, [authenticate, setActiveUser]);

    /**
     * Uses a code returned by an IDP (Google etc) to authenticate a user
     * in our realm.
     */
    const signInWithIdpCode = useCallback(async (code: string, providerName: string, options?: IdpOptions): Promise<IAuthenticationResponse> => {
        const response = await authenticateWithIdpCode({
            variables: {
                code: code,
                provider: providerName,
                options,
                context: ApplicationName
            }
        });

        const {success, token, failureReason, identityProfile} = response.data?.authenticateWithIdpCode;

        if(!(success && !!token)) {
            return {
                success: false,
                failureReason: failureReason as AuthenticationFailureReason,
                identityProfile
            };
        }

        //
        // Set the user and store the returned access token.
        //
        const tokenUser = await validateAccessToken(token);
        if(tokenUser) {
            const appUser = await setActiveUser(tokenUser, token, true);
            return {
                success: true,
                user: appUser
            };
        }
        else {
            return {
                success: false
            };
        }
    }, [authenticateWithIdpCode, setActiveUser]);

    /***
     * Authenticates a user with an OIDC ID token.
     */
    const signInWithIdpIdToken = useCallback(async (idToken: string) => {
        const response = await authenticateWithIdpIdToken({
            variables: {
                token: idToken,
                context: ApplicationName
            }
        })
        const {success, token, failureReason, identityProfile} = response.data?.authenticateWithIdpIdToken;

        if(!(success && !!token)) {
            return {
                success: false,
                failureReason: failureReason as AuthenticationFailureReason,
                identityProfile
            };
        }

        //
        // Set the user and store the returned access token.
        //
        const tokenUser = await validateAccessToken(token);
        if(tokenUser) {
            await setActiveUser(tokenUser, token, true);
            return {
                success: true
            };
        }
        else {
            return {
                success: false
            };
        }
    }, [setActiveUser, authenticateWithIdpIdToken]);

    /**
     * Sometimes (like after creating a new user account) we might be asked
     * to use an access token to set the authenticated user. This is the
     * method to call to do that.
     */
    const setAccessToken = useCallback(async (token: string) => {
        const tokenUser = await validateAccessToken(token);

        //
        // If we can read the token ok and an IApplicationUser was returned,
        // set the current user and store the token.
        //
        if(tokenUser) {
            await setActiveUser(tokenUser, token, true);
            return true;
        }
        return false;
    }, [setActiveUser]);

    const getCurrentAccessToken = useCallback(async () => {
        return localStorage.getItem(AUTH_STORAGE_KEY);
    }, []);

    const allowAutoSignIn = useCallback((allow: boolean) => {
        setAutoSignIn(allow);
    }, []);

    /**
     * FIDO2 compliant passwordless sign in - device must support resident
     * credentials so that the user does not have to enter their email address
     * prior to sign in.
     */
    const signInWithAuthenticator = useCallback(async () => {
        let response = await apollo.query<AssertionOptions>({
            query: ASSERTION_OPTIONS,
            fetchPolicy: "network-only"
        });

        if(response.error) {
            Logger.error(JSON.stringify(response.error));
            return false;
        }

        const opts: PublicKeyCredentialRequestOptionsJSON = response.data.assertionOptions.response as any;
        let assertionResponse: AssertionCredentialJSON;
        try {
            assertionResponse = await startAssertion(opts);
        }
        catch(exc) {
            Logger.error(exc);
            return false;
        }

        let verifyResponse: FetchResult<AssertionVerification>;
        try {
            verifyResponse = await verifyAssertion({
                variables: {
                    input: {
                        ...assertionResponse,
                        context: ApplicationName
                    }
                }
            });
        }
        catch(exc) {
            return false;
        }

        const {success, token} = verifyResponse.data?.assertionVerification;

        if(!success || !token) {
            return false;
        }

        Logger.info("Signed in successfully using a FIDO2 authenticator.");

        //
        // Set the user and store the returned access token.
        //
        const user = await validateAccessToken(token);
        if(user) {
            await setActiveUser(user, token, true);
            return true;
        }
        else {
            return false;
        }

    }, [apollo, verifyAssertion, setActiveUser]);

    const context: IAuthContext = useMemo(() => {
        return {
            user: user,
            checkingIdentity: initialising,
            isUserLinkedToProAccount,
            linkedProAccounts,
            activeCustomerAccount: customerAccount,
            setActiveCustomerAccount,
            loadingCustomerAccount: loadingCustomer,
            supportsCredentialManagement: credentialManagement,
            showSignInDialog: setShowSignIn,
            signInWithEmailAndPassword: signInWithEmailPassword,
            signInWithIdpCode: signInWithIdpCode,
            signInWithIdpIdToken: signInWithIdpIdToken,
            signInWithAuthenticator: signInWithAuthenticator,
            setAccessToken: setAccessToken,
            getCurrentAccessToken: getCurrentAccessToken,
            signOut: signOut,
            allowAutoSignIn: allowAutoSignIn
        }
    }, [
        user,
        initialising,
        isUserLinkedToProAccount,
        linkedProAccounts,
        credentialManagement,
        signInWithEmailPassword,
        signInWithIdpCode,
        signInWithIdpIdToken,
        signInWithAuthenticator,
        signOut,
        allowAutoSignIn,
        setAccessToken,
        getCurrentAccessToken,
        customerAccount,
        setActiveCustomerAccount,
        loadingCustomer
    ]);

    return (
        <AuthContext.Provider value={context}>
            {props.children}
            {loadSignInDialog && (
                <SignInDialog
                    isOpen={showSignIn}
                    onClose={() => setShowSignIn(false)}/>
            )}
            {showValidateMobile && (
                <ValidateMobileDialog />
            )}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;
